import React from 'react';
import { Link, graphql, withPrefix } from 'gatsby';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import LazyHero from 'react-lazy-hero';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import HeroContentContainer from '../../components/HeroContentContainer';

const Stories = (props) => {
  const stories = props.data.allMarkdownRemark.edges;
  return (
    <Layout bodyClass="page-stories" {...props}>
      <SEO title="stories" />
      <LazyHero imageSrc={withPrefix("/heroes/hero2.jpg")} isCentered={false} parallaxOffset={500} minHeight="40vh" opacity={0.35} color="#25a9e0">
        <HeroContentContainer>
          <div className="bottom-left my-2 mx-1 mx-sm-4 mx-md-6">
            <h1>Student Parent Stories</h1>
          </div>
        </HeroContentContainer>
      </LazyHero>

      <div className="container py-2 py-md-6">
        <div className="row">
          {stories.map(edge => (
            <article className="story-teaser col-12 col-md-6 col-lg-4 mb-1 mt-2 mt-md-0" key={edge.node.frontmatter.path} to={edge.node.frontmatter.path}>
              <div className="card">
                <div className="card-content">
                  <img className="story-image" src={withPrefix(edge.node.frontmatter.image)} alt={edge.node.frontmatter.name} />
                  <h2 className="story-name">Meet {edge.node.frontmatter.name}</h2>
                  <p className="story-excerpt">{edge.node.excerpt}</p>
                  <Link
                    className="story-cta text-uppercase text-blue"
                    to={edge.node.frontmatter.path}
                    onClick={() => {
                      trackCustomEvent({
                        category: "Stories",
                        action: "Click",
                        label: `${edge.node.frontmatter.name}`,
                      });
                    }}
                  >
                    <span>View Story</span>
                    <img className="chevron-right" src={withPrefix('/icons/chevron-right.svg')} alt="chevron right" role="presentation" />
                  </Link>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query storiesQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/stories/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            name
            path
            image
          }
        }
      }
    }
  }
`;

export default Stories;
